import Glide from '@glidejs/glide';

function featuredProyectsSlider() {
  const glide = new Glide('.glide.featured-projects', {
    type: 'slider',
    perView: 3,
    gap: '24px',
    bound: true,
    breakpoints: {
      980: {
        perView: 2,
      },
      600: {
        peek: 40,
        perView: 1,
      },
    },
  }).mount();

  
}

function projectCards() {
  const glide = new Glide('.glide.cards', {
    type: 'slider',
    perView: 1,
  }).mount();

  const navList = document.querySelector('.cards_nav ul');
  navList.addEventListener('click', e => {
    e.preventDefault();
    glide.go('=' + e.target.closest('a').dataset.nav);
  });
}

export {featuredProyectsSlider, projectCards};