// footer responsive
export default function footer_size() {
  const footerContact = document.getElementById('footer__contact');
  const lasCol = footerContact.querySelector('.column:last-child');

  function resizeCol() {
    if (window.innerWidth > 980 && window.innerWidth < 1061) {
      lasCol.style.width = footerContact.offsetWidth - 265 + 'px';
    } else if (window.innerWidth > 980) {
      lasCol.style.width = '75%';
    } else {
      lasCol.style.width = '100%';
    }
  }

  resizeCol();

  window.addEventListener('resize', e => {
    resizeCol();
  });
}
