import { Loader } from '@googlemaps/js-api-loader';

function loadMapScript() {
  const loader = new Loader({
    apiKey: 'AIzaSyA4B8ff30ru-HR6YJbl-_9so20X5CYTvI0',
    version: 'weekly',
    // ...additionalOptions,
  });

  return loader;
}

async function loadContactMap() {
  const loader = loadMapScript();

  loader.load().then(async () => {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

    const map = new Map(document.getElementById('map'), {
      mapId: '117bf1c149cf3f57',
      center: { lat: 9.965958688012233, lng: -84.20335873464336 },
      zoom: 12,
    });
    // Customize pin.
    const pinBackground = new PinElement({
      background: '#F2E2BD',
      borderColor: '#A9B089',
      glyphColor: '#A9B089',
    });
    const marker = new AdvancedMarkerElement({
      position: { lat: 9.961855205300235, lng: -84.19233782818938 },
      map,
      content: pinBackground.element,
    });
  });
}

async function loadLocationMap() {
  const locations = dataFromQuery;

  const loader = loadMapScript();

  loader.load().then(async () => {
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

    const map = new Map(document.getElementById('locationMaps'), {
      mapId: '117bf1c149cf3f57',
      center: { lat: 9.947731041711183, lng: -84.14971684213545 },
      zoom: 13,
    });

    const markers = locations.map((position, i) => {
      // Customize pin.
      const pinBackground = new PinElement({
        background: '#F2E2BD',
        borderColor: '#A9B089',
        glyphColor: '#A9B089',
      });
      const marker = new AdvancedMarkerElement({
        map,
        position,
        content: pinBackground.element,
      });
    });
  });
}

export {loadContactMap, loadLocationMap };