import './style.scss';

import { adminHeight } from './scripts/_helpers.js';

import navigation from './scripts/navigation.js';
import footer_size from './scripts/footer.js';
import {loadContactMap, loadLocationMap} from './scripts/maps.js';
import { featuredProyectsSlider, projectCards } from './scripts/sliders.js';
import restructureBlogOnAjax from './scripts/blog.js';
import smoothScroll from './scripts/smoothScroll.js';

import { gsap } from 'gsap/gsap-core';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function init() {

  adminHeight();
  
  smoothScroll();
  navigation();
  footer_size();

  // Contact page
  if (document.querySelector('.page-id-38')) {
      loadContactMap();
  } 
  // Projects page
  if (document.querySelector('.page-id-35')) {
    loadLocationMap();
    projectCards();
  }
  // Home
  if (document.querySelector('.page-id-2')) {
    
    gsap.registerEffect({
      name: "counter",
      extendTimeline: true,
      defaults: {
        end: 0,
        duration: .6,
        ease: "power3",
        increment: 1
      },
      effect: (targets, config) =>{
        let tl = gsap.timeline();
        tl.to(targets, {
          duration: config.duration,
          snap: {innerText:config.increment},
          innerText: config.end,
          ease: config.ease
        });
        return tl;
      }
    });

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#experience_count',
        start: 'top bottom-=100',
        // markers: true
      },
    });
    tl.counter('#experience_count', {
      end: 14,
      duration: 1.2 
    });

  }

  // Home and Projects page
  if (document.querySelector('.page-id-2') || document.querySelector('.page-id-35')) {
    featuredProyectsSlider();
  }
  // Blog
  if (document.querySelector('body.blog')) {   
    restructureBlogOnAjax();
  }

}

// Run on load
window.addEventListener('DOMContentLoaded', function () {
  init();
});

