import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export default function navigation() {
  const btn = document.querySelector('.cta-fixed__link');
  const btnMask = document.querySelector('.cta-fixed__mask');
  const btnLabel = document.querySelector('.cta-fixed__label');

  let mm = gsap.matchMedia();

  // Phone media query
  mm.add("(min-width: 551px)", () => {
      gsap.set(btnMask, { xPercent: 101 });
      gsap.set(btnLabel, { xPercent: -100 });

      function ctaHoverAnimIn(e) {
          e.target.classList.add('opened');
          gsap.to([btnLabel, btnMask], { xPercent: 0, ease: 'power2.inOut' });
      }
      function ctaHoverAnimOut (e) {
        gsap.to(btnMask, { xPercent: 101, ease: 'power2.inOut' });
        gsap.to(btnLabel, {
          xPercent: -100,
          ease: 'power2.inOut',
          onComplete: () => {
            e.target.classList.remove('opened');
          },
        });
      };
    
      btn.addEventListener('mouseenter', ctaHoverAnimIn);
      btn.addEventListener('mouseleave', ctaHoverAnimOut);
      
      return () => {
        // custom cleanup code here (runs when it STOPS matching)
        btn.removeEventListener('mouseenter', ctaHoverAnimIn);
        btn.removeEventListener('mouseleave', ctaHoverAnimOut);
      };
  });
  
  let size = ScrollTrigger.maxScroll(window, false);

  //console.log(size);

  ScrollTrigger.create({
    start: 200,
    end: size + 'bottom',
    toggleClass: {
      targets: 'body',
      className: 'scrolled',
    },
    // markers: true,
    onEnter: () => {
      document.querySelector('.menu-icon').style.visibility = 'visible';
    },
    onLeaveBack: () => {
      setTimeout(() => {
        document.querySelector('.menu-icon').style.visibility = 'hidden';
      }, 600);
    },
  });



    // Hamburguer menu
    const body = document.querySelector('body');
    const menu = document.querySelector('.menu-icon');
    const menuItems = document.querySelectorAll('.fixed-nav__list-item');

    menu.addEventListener('click', () => toggleClass(body, 'nav-active'));


    const toggleClass = (element, stringClass) => {
        if (element.classList.contains(stringClass)) element.classList.remove(stringClass);
        else element.classList.add(stringClass);
    };

}
