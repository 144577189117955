export function wrapElem(target, wraperObj = {}, multipleElem = false) {
  
  // Settings of the wrapper Object
  const settingsObj = {
    elem: 'div',
    ...wraperObj,
  };
  
  // Create wrapper elem
  const newElem = document.createElement(settingsObj.elem);
  if (settingsObj.class) {
    newElem.classList.add(settingsObj.class);
    //newElem.textContent = 'newElem';
  }

  // Handles just one elem
  if (!multipleElem) {
    const targetParent = target.parentNode;
    
    newElem.appendChild(target);
  
    targetParent.appendChild(newElem);

  } else {

    const targetParent = target[0].parentNode;
    
    target.forEach(item => {
      newElem.appendChild(item);
    });

    targetParent.appendChild(newElem);
  }

}

export function adminHeight() {
  // Detect if admin
  const wpBar = document.getElementById('wpadminbar');
  if (wpBar) {
    // Add custom property with admin height
    document.querySelector(':root').style.setProperty('--wp-admin-height', wpBar.offsetHeight + 'px');
  }
}
