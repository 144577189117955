import { wrapElem } from "./_helpers";

function structureBlogCards() {
  // Replace Read More text with Arrow Icon
  const allReadMoreLinks = document.querySelectorAll('.more-link');

  allReadMoreLinks.forEach(link => {
    link.innerHTML = '<span class="et-pb-icon">$</span>';
  });

  // .et_pb_ajax_pagination_container  .post-meta .post-content
  const allBlogCards = document.querySelectorAll('.et_pb_ajax_pagination_container article');

  allBlogCards.forEach(blogCard => {
    const elems = blogCard.querySelectorAll('.post-meta, .post-content');
    // Check if not repeated elem
    if (!elems[0].parentNode.classList.contains('blogCard_meta')) {
      wrapElem(elems, { class: 'blogCard_meta' }, true);
    }
  });
}

export default function restructureBlogOnAjax() {
  // Create the featured_info wrapper for styling
  const featInfo_elem = document.querySelectorAll('#featured_post .entry-title, #featured_post .post-meta, #featured_post .post-content');
  wrapElem(featInfo_elem, { class: 'featured_info' }, true);

  // Finish structuring blog feed elems
  structureBlogCards();

  // Catch AJAX call
  // Store the original open method
  const originalOpen = XMLHttpRequest.prototype.open;

  // Overwrite the native open method
  XMLHttpRequest.prototype.open = function (...args) {
    // Call the stored reference to the native open method with arguments
    originalOpen.apply(this, args);

    // Assign an event listener
    this.addEventListener(
      'load',
      event => {
        if (this.readyState === XMLHttpRequest.DONE) {
          // The request is complete, and you can call structureBlogCards
          setTimeout(() => {
            structureBlogCards();
          }, 10);
        }
      },
      false
    );
  };
}